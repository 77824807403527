<template>
  <ReducedFareValidation>
    <template
      #summary
    >
      <p>Enter your TAP Card number to verify that you are eligible for a discounted pass.</p>
    </template>
    <template #eligibility-fields>
      <MaskedTextInput
        v-model="account.cart.discountVerificationForm.metroTAP"
        :oruga-field-props="useErrorProps.fieldProps"
        :oruga-input-props="orugaInputProps"
        mask="XXXX XXXX XXXX XXXX XXXX"
        @valid="v => valid = v"
      />
      <div>
        <p>
          <img
            :src="getTapCardUrl()"
            width="300"
            height="189"
            alt=""
          >
        </p>
        <p id="tap-location-desc">
          <em>Your TAP card number is located on the bottom right of your TAP
            card.</em>
        </p>
      </div>
    </template>
    <template
      #details
    >
      <p>
        Your Reduced Fare TAP card cannot be used to pay for your Metro Bike
        Share pass. Entering your number will only verify that you are
        eligible for this discount. Metro Bike Share passes must be purchased
        using a debit or credit card.
      </p>
      <p>
        Up to four family members of the same household are eligible to sign
        up with the same TAP card.
      </p>
      <p>
        We respect your privacy! Your TAP card number will not be shared or
        used for any reason other than to verify your eligibility. For any
        questions, please call or text our help desk at (844) 857-2453 or
        email
        <a
          href="mailto:support@bikeshare.metro.net"
        >support@bikeshare.metro.net</a>.
      </p>
      <p>
        Low-Income Fare is Easy (LIFE) TAP patrons can enroll in discounted
        Metro Bike Share passes at
        <ExternalLink
          href="https://www.taptogo.net/MetroBikeShare"
          text="taptogo.net/metrobikeshare"
        />
        or select EBT verification above.
      </p>
    </template>
  </ReducedFareValidation>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import { useAccount } from "../../../../stores/account";
import ExternalLink from "../../../ExternalLink.vue";
import MaskedTextInput from "../../../MaskedTextInput.vue";
import ReducedFareValidation from "../ReducedFareValidationTemplate.vue";
import { useFieldErrorProvider } from "../../../FieldErrorProvider/useFieldErrorProvider";

const account = useAccount();

const valid = ref(false);
const useErrorProps = useFieldErrorProvider(
  () => account.cart.discountVerificationForm.metroTAP,
  "metroTAP",
  { valid },
);

const orugaInputProps = {
  ...useErrorProps.inputProps,
  "name": "tap-card",
  "required": true,
  "autofocus": true,
  "aria-describedby": "tap-location-desc",
};

const getTapCardUrl = () => {
  return new URL("../../../../../assets/images/tap-card-number-location@2x.png", import.meta.url).href;
};

onUnmounted(() => {
  useErrorProps.clearErrors();
});
</script>
