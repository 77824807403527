import { AccessPass, CaliforniaEBT, DisabilityValidation, MetroTAP, SeniorSelfCert, NevadaEBT, StudentValidation } from "./validation";
import { NOT_ELIGIBLE_TYPE } from "../../stores/stateDefaults";

export const needsRecertifiedFunctions = {
  // this is currently not in use
  never: () => true,
  oneYear: lastValidatedDate => ((new Date() - lastValidatedDate) < 1000 * 60 * 60 * 24 * 365),
};

export const notEligible = {
  title: "Full Fare",
  description: "Not eligible for reduced fare",
  image: "fa-bicycle.svg",
  type: NOT_ELIGIBLE_TYPE,
};

export const reducedFareTypes = {
  phl: [
    notEligible,
    {
      title: "Reduced Fare",
      description: "For PA ACCESS card holders",
      type: "accessCard",
      recertFrequency: "oneYear",
      image: "access-passes.svg",
      validationComponent: AccessPass,
    },
  ],
  lax: [
    notEligible,
    {
      title: "EBT",
      description: "For Golden State Advantage EBT cardholders",
      type: "californiaEBT",
      recertFrequency: "oneYear",
      image: "ebt-icon.svg",
      validationComponent: CaliforniaEBT,
    },
    {
      title: "Student",
      description: "For students 16+",
      type: "studentEmail",
      recertFrequency: "oneYear",
      image: "student-icon.svg",
      validationComponent: StudentValidation,
    },
    {
      title: "Senior",
      description: "For seniors 62+",
      type: "senior",
      recertFrequency: "never",
      image: "senior-icon.svg",
      validationComponent: SeniorSelfCert,
    },
    {
      title: "Disability",
      description: "For people with ADA-recognized disabilities",
      type: "disability",
      recertFrequency: "never",
      image: "disabled-icon.svg",
      validationComponent: DisabilityValidation,
    },
    {
      title: "Reduced Fare TAP",
      description: "For Transit Assistance Program cardholders",
      type: "metroTAP",
      recertFrequency: "oneYear",
      image: "tap-icon.svg",
      validationComponent: MetroTAP,
    },
  ],
  las: [
    notEligible,
    {
      title: "Nevada EBT Card",
      description: "For Nevada EBT cardholders",
      type: "nevadaEBT",
      recertFrequency: "oneYear",
      image: "nevada-ebt.png",
      validationComponent: NevadaEBT,
    },
    // TODO: this isn't used in the account portal. Should we implement it here?
    // {
    //   title: "RTC Reduced Fare Card",
    //   description: "For RTC Reduced Fare cardholders",
    //   type: "rtcRFID",
    //   recertFrequency: "oneYear",
    //   // TODO: what image should we use?
    //   image: "nevada-ebt.png",
    //   validationComponent: ReducedFareRTC,
    // },
  ],
};